.contacts {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #E1E1E1;

  &__container  {
    width: 100dvw;
    max-width: 100% !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .left_block {
      width: 100%;
      height: 100%;

      flex: 0.5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      color: var(--white);
      text-transform: uppercase;
      text-align: center;

      background: url("../../../shared/assets/images/gift/GiftBgIcon.webp") center center;
      background-size: cover;

      .gift_data {
        &__content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          width: 350.67px;
          height: 252px;
          padding: 19.83px 28.33px 19.83px 28.33px;
          gap: 14.17px;
          border-radius: 41.08px;

          background: linear-gradient(0deg, rgba(250, 250, 250, 0.2), rgba(250, 250, 250, 0.2)),
          linear-gradient(86.6deg, rgba(0, 0, 0, 0) 0.16%, rgba(0, 0, 0, 0.28) 55.12%);
          background-blend-mode: color;
          backdrop-filter: blur(10px);

          border: 0.88px solid;
          border-image-source: linear-gradient(141.25deg, #FFFFFF -4.08%, rgba(153, 153, 153, 0) 89.39%);
        }
      }

      h2 {
        font-family: Inter;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-align: center;
      }
      h3 {
        font-family: Roboto;
        font-size: 90.67px;
        font-weight: 700;
        line-height: 117.87px;
        letter-spacing: -0.01em;
        text-align: center;
      }
      p {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        text-align: center;
      }

      img {
        max-width: 376px;
        height: auto;
        margin-left: -20px;
      }
    }

    .right_block {
      width: 100%;
      height: calc(100vh - 2px);

      flex: 0.5;
      display: flex;
      justify-content: center;
      align-items: center;

      border: 1px solid var(--neutral);
      background: #E1E1E1;

      form {
        width: 100%;
        padding: 34px;
        background: #E1E1E1;

        h3 {
          font-family: IBM Plex Sans;
          font-size: 36px;
          font-weight: 700;
          line-height: 46.8px;
          text-align: left;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .contacts {
    &__container {

      .left_block {
        flex-wrap: nowrap;

        .gift_data {
          &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 250px;
            height: 200px;
            padding: 19.83px 28.33px 19.83px 28.33px;
            gap: 14.17px;
            border-radius: 41.08px;

            background: linear-gradient(0deg, rgba(250, 250, 250, 0.2), rgba(250, 250, 250, 0.2)),
            linear-gradient(86.6deg, rgba(0, 0, 0, 0) 0.16%, rgba(0, 0, 0, 0.28) 55.12%);
            background-blend-mode: color;
            backdrop-filter: blur(10px);

            border: 0.88px solid;
            border-image-source: linear-gradient(141.25deg, #FFFFFF -4.08%, rgba(153, 153, 153, 0) 89.39%);
          }
        }

        h2 {
          font-size: 3vw;
          line-height: 4vw;
          text-wrap: nowrap;
        }
        h3 {
          font-size: 6vw;
          line-height: 8vw;
        }
        p {
          font-size: 2vw;
          line-height: 2vw;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .contacts {
    &__container {
      .left_block {
        .gift_data {
          &__content {
            width: 200px;
            height: 170px;
          }
        }
      }

      .right_block {
        form {
          h3 {
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .contacts {
    align-items: start;

    &__container {
      .right_block {
        overflow-y: auto;

        form {
          //margin-top: 180px;

          margin-top: -20px;

          h3 {
            text-align: center;
            margin-bottom: 30px;

            font-family: Roboto;
            font-size: 23px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0em;
          }
        }
      }
    }
  }
}

@media (max-width: 655px) {
  .contacts {
    padding: 0;
    background: white;

    &__container {
      width: 100%;
      max-width: 100% !important;
      display: block;
      align-items: start;
      background: white;

      .left_block {
        max-width: 100%;
        max-height: 100%;
        padding: 0 15px;
      }

      .right_block {
        width: 100%;
        border: none;
        align-items: start;
        background: white;

        form {
          //margin-top: 120px;
          width: 100%;
          background: var(--white);
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .contacts {
    padding: 0;

    &__container {
      width: 100%;
      max-width: 100% !important;
      display: block;

      .left_block {
        max-width: 100%;
        max-height: 100%;
        padding: 0 15px;
      }

      .right_block {
        width: 100%;
        border: none;

        form {
          //padding-top: 120px;
          width: 100%;
          background: var(--white);
        }
      }
    }
  }
}