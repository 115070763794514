.modal {
  &__title {
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    color: var(--dark-blue);
  }
  &__content {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    text-align: center;

    button {
      width: 100%;
    }
  }
  &__desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    color: #475467;
  }
}