.page {
  width: 100%;
  height: 100vh;
  background: #E7E7E7;

  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .gift_data {
      position: relative;
      width: calc(100% - 30px);
      min-height: 268px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
      background: url("../../../shared/assets/images/gift/GiftBgIcon.webp");
      background-size: cover;
      background-position: 0 -60px;
      background-color: #00000033;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #00000033;
        z-index: 1;
      }

      &__content {
        z-index: 2;
        width: 100%;
        max-width: 304.52px;
        height: 219.36px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        top: 24px;
        left: 28px;
        padding: 12.36px 17.65px 12.36px 17.65px;
        gap: 8.83px;
        border-radius: 25.59px;

        background: linear-gradient(0deg, rgba(250, 250, 250, 0.2), rgba(250, 250, 250, 0.2)),
        linear-gradient(86.6deg, rgba(0, 0, 0, 0) 0.16%, rgba(0, 0, 0, 0.28) 55.12%);
        background-blend-mode: color;
        backdrop-filter: blur(10px);

        border: 0.88px solid;
        border-image-source: linear-gradient(141.25deg, #FFFFFF -4.08%, rgba(153, 153, 153, 0) 89.39%);
      }
      &__content_sum {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        img:first-child {
          transform: scaleX(-1);
        }
      }

      h3 {
        color: var(--white);

        font-size: 19.93px;
        font-weight: 600;
        line-height: 24.92px;
        letter-spacing: -0.02em;

        text-align: center;
        text-transform: uppercase;
      }
      h2 {
        margin: 15px 0;
        color: var(--white);

        font-family: Roboto;
        font-size: 56.48px;
        font-weight: 700;
        line-height: 57px;
        letter-spacing: -0.01em;
        text-align: center;
      }
      p {
        color: var(--white);

        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;

        text-align: center;
      }
    }

    .cards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      gap: 15px;

      &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 150px;
        max-width: 100%;
        height: 55px;
        padding: 15px;
        border-radius: 6px;
        background: var(--white);

        p {
          padding-top: 5px;
          color: #000000;

          font-family: Roboto;
          font-size: 15px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: -0.01em;
          text-align: left;

          span {
            font-family: Roboto;
            color: #51AF3D;

            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0;
            text-align: left;
          }
        }
      }
      &__bottom_block {
        display: flex;
        flex-direction: row;
        gap: 15px;

        .cards {
          &__card {
            p {
              color: #000000;

              font-family: Roboto;
              font-size: 15px;
              font-weight: 400;
              line-height: 19px;
              letter-spacing: -0.01em;
              text-align: left;

              span {
                color: #51AF3D;

                font-family: IBM Plex Sans;
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
              }
            }

            .card_desc {
              color: #000000;

              font-family: Roboto;
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: -0.01em;
              text-align: left;
            }
          }
        }
      }
    }

    .button {
      width: calc(100% - 50px);
      align-self: center;
      margin-top: auto;
      padding-top: 37px;
      padding-bottom: 60px;
      margin-bottom: 60px;

      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 400px) {
  .page {
    .container {
      .gift_data {
        padding: 15px;
      }

      .cards {
        margin-top: 20px;
        gap: 10px;

        &__card {
          padding: 5px;

          p {
            padding-top: 3px;
          }
        }
        &__bottom_block {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }

      .button {
        width: calc(100% - 40px);
      }
    }
  }
}