.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 30px;
  background: white;

  &__title {
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: var(--dark-blue);
  }
  &__names {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  &__input_block {
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 5px;
    }

    &--tel {
      position: relative;

      .inputPhone {
        padding-left: 57px !important;

        &__country {
          content: '';
          position: absolute;
          top: 14px;
          left: 10px;
          width: 22px;
          height: 23px;

          display: flex;
          align-items: center;

          cursor: pointer;

          &__flag {
            width: 100%;
            height: auto;
          }

          &__cevron {
            width: 20px;
            height: 20px;
            padding-left: 4px;
            opacity: 0.6;
          }
        }

        &__countries_dropdown {
          z-index: 9999;
          width: 100%;
          max-width: 130px;

          content: '';
          position: absolute;
          top: -30px;
          left: 10px;

          border: 1px solid var(--light-grey);
          border-radius: 6px;
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0392156863);
          background-color: var(--white2);
        }
        &__countries_dropdown_container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 12px;
          padding: 3px 10px;
          cursor: pointer;

          &:hover {
            background: #72BF44;
          }

          img {
            width: 20px !important;
            min-width: 20px !important;
            max-width: 20px !important;
            height: auto;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
  &__input_block_error {
    input {
      border: 1px solid red !important;
    }
  }
  &__title-mess {
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    color: var(--dark-blue);
  }
  &__messengers {
    display: flex;
    gap: 20px;

    .checkbox {
      label {
        // fix this
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 21px !important;
        letter-spacing: 0 !important;
      }
    }
  }

  .errors {
    padding: 0;
    margin: 0;
    color: red;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;

    white-space: pre-line;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    padding: 12px 16px;
    border: 1px solid var(--light-grey);
    border-radius: 6px;

    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  button {
    width: 100%;
  }
}

.checkboxRect {
  margin-right: 10px;
  input[type="checkbox"] {
    display: none;
    + {
      label {
        display: block;
        position: relative;
        padding-left: 30px;
        font-size: 14px;
        padding-top: 2px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        &:before {
          content: "";
          display: block;
          width: 1.4em;
          height: 1.4em;
          border: 1px solid #d1d2d4;
          border-radius: 0.2em;
          position: absolute;
          left: 0;
          top: 0;
          -webkit-transition: all 0.2s, background 0.2s ease-in-out;
          transition: all 0.2s, background 0.2s ease-in-out;
          background: #ffffff;
        }
      }
    }
    &:hover {
      + {
        label {
          &:before {
            background: #89d3a5;
            box-shadow: inset 0 0 0 2px #fff;
          }
        }
      }
    }
    &:checked {
      + {
        label {
          &:before {
            width: 1.3em;
            height: 1.3em;
            border-radius: 0.2em;
            border: 2px solid #fff;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            background: #51AF3D;
            box-shadow: 0 0 0 1px #51AF3D;
          }
        }
      }
    }
  }
}

.checkSmallLabel {
  max-width: 464px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.checkSmallLabelText {
  color: #5f5f5f;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.checkSmall {
  box-sizing: border-box;
  width: 13px;
  min-width: 13px;
  max-width: 13px;
  height: 13px;
  min-height: 13px;
  max-height: 13px;
  transform: scale(1.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  accent-color: #51AF3D;
  color: #51AF3D;
  border: 1px solid #d1d2d4;
  cursor: pointer;

  &:checked {
    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 11px;
      min-width: 11px;
      max-width: 11px;
      height: 11px;
      min-height: 11px;
      max-height: 11px;

      background-image: url("../../../shared/assets/icons/CheckIcon.webp");
      background-color: #51AF3D;
      background-size: contain;
    }
  }

  &:hover {
    &:before {
      background-color: #81c956;
    }
  }
}

@media (max-width: 992px) {
  .form {
    gap: 15px;

    &__names {
      gap: 15px;
    }
    &__messengers {
      gap: 15px;
    }

    &__input_block {
      &--tel {
        .inputPhone {
          &__country {
            top: 11px;
          }
        }
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"] {
      padding: 8px;
    }
  }
}
@media (max-width: 767.9px) {
  .form {
    margin-top: 20px;

    &__input_block {
      &--tel {
        .inputPhone {
          &__country {
            top: 10px;
          }
        }
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: start;

      br {
        display: none;
      }
    }
    &__names {
      width: 100%;
      flex-direction: column;
      gap: 15px;

      &_block {
        input {
          margin-bottom: 10px;
        }
      }

      input {
        width: calc(100% - 20px);
      }
    }
  }
}
@media (max-width: 655px) {
  .form {
    &__input_block {
      &--tel {
        .inputPhone {
          &__country {
            top: 9px;
          }
        }
      }
    }

    &__title {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.1px;
    }
  }
}