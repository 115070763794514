.header {
  .layout {
    background: var(--white);
    border-bottom: 1px solid #EAEBF0;
  }

  .left_block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }
  .right_block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .call_info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      p {
        max-width: 115px;
        color: #5F6D7E;

        font-family: Inter;
        font-size: 9px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.02em;
        text-align: left;

      }
    }
  }

  &--fixed {
    z-index: 3;
    width: 100%;
    position: fixed;
  }
  &__left_icon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 18px;
    left: 25px;
  }
}

@media (max-width: 992px) {
  .header {
    .left_block {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 32px;
    }
    .right_block {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;

      button {
        width: 225px;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .header {
    .layout {
      div {
        justify-content: center;
        gap: 20px;
      }
    }
  }
}