.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  //overflow: auto;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 0;

    .left_block {
      height: 100%;
      //flex: 0.7;
      flex: 1;
      padding: 45px 25px 25px 85px;

      .logo {
        margin-bottom: 20px;
      }


      .questions {
        h2 {
          color: #0F172A;
          padding-right: 15px;

          font-family: Roboto;
          font-size: 36px;
          font-weight: 700;
          line-height: 47px;
          letter-spacing: 0em;
          text-align: left;
        }

        .content {
          margin-top: 60px;
        }
      }
    }

    .right_block {
      //height: calc(100vh - 141px);
      height: 100%;
      min-height: calc(100vh - 141px);
      max-height: 100vh;
      width: 315px;
      min-width: 315px;
      max-width: 315px;
      //height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      //flex: 0.3;
      padding: 30px 15px 30px 15px;
      gap: 10px;
      background: #E1E1E1;

      .card {
        height: 100%;
        max-height: 90px;
        max-width: 295px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        background: #F6F6F6;
        padding: 15px 10px 15px 10px;
        border-radius: 15px 15px 15px 4px;


        &__container {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        &__title_container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
        }
        &__title {
          color: #272D37;

          font-family: Roboto;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: -0.01em;
          text-align: left;
        }
        &__dot {
          text-align: start;
          padding-bottom: 3px;

          img {
            width: 6px;
            height: 6px;
          }
        }
        &__desc {
          min-width: 130px;
          color: #272D37;

          font-family: Roboto;
          font-size: 10px;
          font-weight: 300;
          line-height: 12px;
          letter-spacing: -0.01em;
          text-align: left;
        }
        &__message {
          color: #272D37;

          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.01em;
          text-align: left;
        }

        &__manager {
          width: 38.64px;
          min-width: 38.64px;
          height: 38.64px;
          min-height: 38.64px;
        }
      }

      .gift {
        height: 100%;
        max-height: 317px;
        width: 100%;
        min-width: 317px;
        max-width: 317px;
        box-sizing: border-box;
        padding: 15px 26px 15px 26px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 18px;
        background: #51AF3D;
        border: 3px solid #74E35D;
        color: var(--white);

        p {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: -0.01em;
          text-align: left;
        }

        h3 {
          font-family: Roboto;
          font-size: 36px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: -0.01em;
          text-align: left;

        }
      }
    }
  }
}

@media (max-width: 992px) {
  .page {
    .container {
      .left_block {
        height: 100%;
        padding-top: 30px;
        padding-left: 30px;

        .questions {
          .content {
            padding-bottom: 30px;
            margin-top: 30px;
          }
        }
      }

      .right_block {
        .card {
          height: auto;
          max-width: 100%;
        }

        .gift {
          height: 100%;
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    overflow: initial;

    .container {
      height: 100%;
      margin-bottom: 20px;
      //overflow: auto;

      .left_block {
        height: 100%;
        flex: 1;
        padding: 0 30px;
        margin: 0;

        .card {
          height: 100%;
          max-height: 90px;
          max-width: 295px;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 20px;
          margin-bottom: 10px;

          background: #F6F6F6;
          padding: 15px 10px 15px 10px;
          border-radius: 30px 30px 30px 5px;

          &__container {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }

          &__title_container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
          }
          &__title {
            color: #272D37;

            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: -0.01em;
            text-align: left;
          }
          &__dot {
            text-align: start;
            padding-bottom: 3px;

            img {
              width: 6px;
              height: 6px;
            }
          }
          &__desc {
            color: #272D37;

            font-family: Roboto;
            font-size: 10px;
            font-weight: 300;
            line-height: 12px;
            letter-spacing: -0.01em;
            text-align: left;
          }
          &__message {
            color: #272D37;

            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.01em;
            text-align: left;
          }

          &__manager {
            width: 38.64px;
            min-width: 38.64px;
            height: 38.64px;
            min-height: 38.64px;
          }
        }
      }
    }
  }
}

@media (max-width: 655px) {
  .page {
    .container {
      margin-bottom: 0;

      .left_block {
        padding-top: 25px;
        padding-left: 25px;

        .questions {
          h2 {
            color: #0F172A;
            padding-right: 15px;

            font-family: Roboto;
            font-size: 24px;
            font-weight: 700;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }
  }
}